.game-picker .games .game.selected {
  background: var(--bs-tertiary-bg);
}

.game-picker .games .game:not(:last-child) {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color);
}

.game-picker .games .game .game-thumbnail {
  background: var(--bs-tertiary-color);
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: relative;
  border-radius: 0.3rem;
}

.game-picker .games .game .game-thumbnail img {
  display: block;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
