/*
 * Wrapper
 * -------------------------------------------------- */

.react-datepicker-wrapper {
  display: block;
}

/*
 * Picker
 * -------------------------------------------------- */

.react-datepicker {
  display: flex;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  z-index: 1;
}

.react-datepicker__header {
  background: none;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  height: auto;
  padding: 0.7rem;
}

.react-datepicker__navigation {
  top: 0.5rem;
  height: 1.82rem;
  width: 1.82rem;
}

.react-datepicker__navigation-icon {
  top: 0.32rem;
}

.react-datepicker__navigation-icon--next {
  left: 0;
}

.react-datepicker__navigation-icon--previous {
  right: 0;
}

/*
 * Date Picker
 * -------------------------------------------------- */

.react-datepicker__month-container {
  background: none;
  border-left: var(--bs-border-width) solid var(--bs-border-color);
  flex-grow: 1;
}

.react-datepicker__month-container:first-of-type {
  border-left: none;
}

.react-datepicker__current-month {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
}

.react-datepicker__day {
  margin: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.react-datepicker__day,
.react-datepicker__day-name {
  color: var(--bs-body-color);
}

.react-datepicker__day:hover {
  background: var(--bs-tertiary-bg);
  border-radius: 0;
}

.react-datepicker__day--today {
  background: var(--bs-secondary-bg);
  border-radius: 0;
  font-weight: var(--bs-body-font-weight);
}

.react-datepicker__day--in-range {
  background: var(--bs-primary-bg-subtle);
  border-radius: 0;
}

.react-datepicker__day--range-start {
  background: var(--bs-primary);
}

.react-datepicker__day--range-end {
  background: var(--bs-primary);
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  border-radius: 0;
  background: var(--bs-primary);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
  border-radius: 0;
  background: none;
}

.react-datepicker__day--outside-month,
.react-datepicker__day--outside-month:hover {
  color: var(--bs-tertiary-color);
}

/*
 * Time Picker
 * -------------------------------------------------- */

.react-datepicker__time-container {
  border-left: var(--bs-border-width) solid var(--bs-border-color);
  overflow: hidden;
}

.react-datepicker__time-container .react-datepicker__time {
  background: none;
}

.react-datepicker-time__header {
  background: none;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
}

.react-datepicker__header--time {
  padding: 0.7rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  cursor: pointer;
  padding: 0;
  line-height: 1.5rem;
  height: auto;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--bs-tertiary-bg);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--bs-primary) !important;
  font-weight: var(--bs-body-font-weight);
}

/*
 * Within Portal
 * -------------------------------------------------- */

.react-datepicker__portal {
  background: rgba(0, 0, 0, 0.5);
}

.react-datepicker__portal .react-datepicker__navigation {
  height: 2.82rem;
  width: 2.82rem;
  top: 0;
}

/*
 * Date Picker
 * -------------------------------------------------- */

.react-datepicker__portal .react-datepicker__current-month {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
}

.react-datepicker__portal .react-datepicker__month {
  margin: 0.7rem 0.4rem;
}

.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__day-name {
  line-height: 2rem;
  width: 2rem;
}

/*
 * Time Picker
 * -------------------------------------------------- */

.react-datepicker__portal .react-datepicker-time__header {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
}

.react-datepicker__portal .react-datepicker__header--time {
  padding: 0.7rem;
}

.react-datepicker__portal
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  line-height: 1.8rem;
}

/*
 * General
 * -------------------------------------------------- */

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled,
.react-datepicker__year-text--disabled:hover {
  cursor: default;
  color: var(--bs-danger);
  background: none;
  opacity: 0.5;
}

.react-datepicker__time-list-item--disabled {
  cursor: default;
  color: var(--bs-danger) !important;
  background: none;
  opacity: 0.5;
}

.react-datepicker-popper[data-placement] .react-datepicker__triangle {
  stroke: var(--bs-border-color);
  fill: var(--bs-body-bg);
  color: var(--bs-body-bg);
  z-index: 1;
}
