/*
 * Tournament Card Link
 * -------------------------------------------------- */

.tournament-card-link,
.tournament-card-link:hover {
  text-decoration: none;
  color: inherit;
}

.tournament-card-link:hover .card {
  border-color: var(--bs-primary);
}

/*
 * Tournament Card
 * -------------------------------------------------- */

.tournament-card {
  width: 18rem;
  margin: 0.5rem;
}

.tournament-card .card-img-top {
  height: 7rem;
  background-color: var(--bs-secondary-bg);
  border: none;
  object-fit: cover;
  object-position: 0 -20px;
}

.tournament-card .metrics svg {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}
