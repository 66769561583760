/*
 * App Main Navbar
 * -------------------------------------------------- */

#app #main-navbar {
  background-color: var(--bs-body-bg);
}

#app #main-navbar .nav-link {
  display: flex;
  align-items: center;
}

#app #main-navbar .nav-link svg {
  margin-right: 0.255em;
  vertical-align: 0.255em;
}

/*
 * App View
 * -------------------------------------------------- */

#app #view .page-title {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

#app #view .page-title svg {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

/*
 * General
 * -------------------------------------------------- */

.card:has(.form-control.is-invalid) {
  border: solid 1px var(--bs-danger);
}

.border.border-invalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}
