.tournament-game {
  padding: 0 0.75rem;
}

.tournament-game .game {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  font-size: 0.875em;
  line-height: 1rem;
  position: relative;
}

.tournament-game .game .thumbnail {
  background: var(--bs-tertiary-color);
  width: 30px;
  min-width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  margin-right: 0.5rem;
}

.tournament-game .game .thumbnail img {
  display: block;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tournament-game .game .info {
  flex: 1 1 auto;
  align-self: center;
}

.tournament-game .buttons {
  position: absolute;
  bottom: -10px;
  right: -5px;
}

.tournament-game .button-left,
.tournament-game .button-right {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 7px;
}

.tournament-game:not(:hover) .button-left,
.tournament-game:not(:hover) .button-right {
  display: none;
}
